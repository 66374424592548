import clsx from 'clsx';

import { SliderBlockFragment as HellbergSliderBlockFragment } from '@hultafors/hellberg/types';
import { SliderBlockFragment as HultaforsSliderBlockFragment } from '@hultafors/hultafors/types';
import { SliderBlockFragment as SnickersSliderBlockFragment } from '@hultafors/snickers/types';
import { SliderBlockFragment as SolidgearSliderBlockFragment } from '@hultafors/solidgear/types';

import styles from './slider-block.module.scss';
import SwiperContent from './swiper-wrapper';

require('swiper/css');
require('swiper/css/navigation');
require('swiper/css/pagination');

type SliderBlockFragment =
  | SnickersSliderBlockFragment
  | SolidgearSliderBlockFragment
  | HellbergSliderBlockFragment
  | HultaforsSliderBlockFragment;

export interface SliderBlockProps {
  blockProps: Partial<SliderBlockFragment>;
  ariaLabelNext?: string;
  ariaLabelPrevious?: string;
  ariaLabelSliderNavigation?: string;
  children: React.ReactNode;
  className?: string;
}

export const SliderBlockShared: React.FC<SliderBlockProps> = ({
  blockProps,
  ariaLabelNext,
  ariaLabelPrevious,
  ariaLabelSliderNavigation,
  children,
  className,
}) => {
  return (
    <div className={clsx(styles['slider-block'], className)}>
      <SwiperContent
        blockProps={blockProps}
        ariaLabelNext={ariaLabelNext}
        ariaLabelPrevious={ariaLabelPrevious}
        ariaLabelSliderNavigation={ariaLabelSliderNavigation}
      >
        {children}
      </SwiperContent>
    </div>
  );
};
