import React from 'react';

import { ImageProps, ImgProps } from 'next/dist/shared/lib/get-img-props';
import { getImageProps } from 'next/image';

import { datoImageLoader } from '@hultafors/shared/helpers/dato-image-loader/dato-image-loader';

type ResponsiveImageFragment = {
  __typename?: 'FileField';
  alt?: string | null;
  title?: string | null;
  url: string;
  blurUpThumb?: string | null;
  focalPoint?: { __typename?: 'focalPoint'; x: any; y: any } | null;
  colors?: Array<{ __typename?: 'ColorField'; hex: string }>;
};

interface ResponsiveImageProps {
  alt?: string | null;
  image?: ResponsiveImageFragment | null;
  priority?: boolean;
  sizesPortrait?: string;
  sizesLandscape?: string;
  className?: string;
  aspectRatioPortrait?: number;
  aspectRatioLandscape?: number;
  maxSize?: number;
  style?: React.CSSProperties;
}

export const ResponsiveImage: React.FC<ResponsiveImageProps> = ({
  image,
  alt: altInput,
  priority,
  sizesPortrait = '100vw',
  sizesLandscape = '100vw',
  className,
  aspectRatioPortrait = 3 / 4,
  aspectRatioLandscape = 21 / 9,
  maxSize = 2880,
  style,
}) => {
  let portrait: ImgProps | null = null;
  let landscape: ImgProps | null = null;

  const alt = altInput || '';

  if (image?.url) {
    const imageUrl = new URL(image.url);
    imageUrl.searchParams.set('q', '85');
    imageUrl.searchParams.set('crop', 'focalpoint');
    imageUrl.searchParams.set('fit', 'crop');
    imageUrl.searchParams.set('fp-x', String(image.focalPoint?.x || 0.5));
    imageUrl.searchParams.set('fp-y', String(image.focalPoint?.y || 0.5));
    imageUrl.searchParams.set('h', String(maxSize));
    imageUrl.searchParams.set('w', String(maxSize));
    const common: Omit<ImageProps, 'src'> = {
      alt,
      loader: datoImageLoader,
      priority,
      quality: 85,
    };

    const widthPortrait = maxSize * aspectRatioPortrait;
    const heightLandscape = maxSize / aspectRatioLandscape;
    const imageUrlPortrait = new URL(imageUrl.href);
    imageUrlPortrait.searchParams.set('w', String(widthPortrait));
    const imageUrlLandscape = new URL(imageUrl.href);
    imageUrlLandscape.searchParams.set('h', String(heightLandscape));

    portrait = getImageProps({
      ...common,
      height: maxSize,
      sizes: sizesPortrait,
      src: imageUrlPortrait.href,
      width: widthPortrait,
    }).props;

    landscape = getImageProps({
      ...common,
      height: heightLandscape,
      sizes: sizesLandscape,
      src: imageUrlLandscape.href,
      width: maxSize,
    }).props;
  }

  const loading = priority ? 'eager' : 'lazy';

  return (
    <figure className={className} style={style}>
      {portrait && landscape && (
        <picture>
          <source
            srcSet={portrait.srcSet}
            media="(orientation: portrait)"
            sizes={sizesPortrait}
          />
          <source
            srcSet={landscape.srcSet}
            media="(orientation: landscape)"
            sizes={sizesLandscape}
          />
          <img src={portrait.src} alt={alt} loading={loading} />
        </picture>
      )}
    </figure>
  );
};
